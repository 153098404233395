import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import '@fortawesome/fontawesome/styles.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import './scss/animate.css';
import './scss/index.scss';

import './assets/particles.json';
import './assets/apple-touch-icon-57x57.png';
import './assets/apple-touch-icon-60x60.png';
import './assets/apple-touch-icon-72x72.png';
import './assets/apple-touch-icon-76x76.png';
import './assets/apple-touch-icon-114x114.png';
import './assets/apple-touch-icon-120x120.png';
import './assets/apple-touch-icon-144x144.png';
import './assets/apple-touch-icon-152x152.png';
import './assets/favicon-16x16.png';
import './assets/favicon-32x32.png';
import './assets/favicon-96x96.png';
import './assets/favicon-128.png';
import './assets/favicon-196x196.png';
import './assets/favicon.ico';
import './assets/mstile-70x70.png';
import './assets/mstile-144x144.png';
import './assets/mstile-150x150.png';
import './assets/mstile-310x150.png';
import './assets/mstile-310x310.png';

import {ThreeHeader} from "./ThreeHeader";

declare const particlesJS: any;
particlesJS.load('particles', 'dist/particles.json', null);